import React from 'react';
import styles from './TopRelevantCategories.module.scss';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_CATEGORY_SEARCH } from '../../utils/appRoutes.definitions';
import slugify from '../../utils/slugify';
import { Link } from 'react-router-dom';
import { useConstructURL } from '../../utils/useConstructURL';

const TopRelevantCategories = ({ classList, topCategories }) => {
  const constructURL = useConstructURL();

  if (!topCategories?.length) return null;
  return (
    <div className={classList?.root || styles.root}>
      {topCategories.map((category) => {
        const categoriesNames = category.split('>');
        const displayName = categoriesNames.slice(-1).toString();
        const categoriesPath = categoriesNames.map(slugify);
        return (
          <Link
            key={category}
            to={constructURL(getPagePath(ARD_CATEGORY_SEARCH, categoriesPath))}
            className={classList?.category || styles.category}
          >
            {displayName}
          </Link>
        );
      })}
    </div>
  );
};

export default TopRelevantCategories;
