import { useEffect, useState } from 'react';
import { getValidResponseBody } from '../../api';
import searchApi from '../../api/searchApi';
import { pipeSingleValue } from '../../utils/fnpipe';

const useFacetsFetcher = (facetsDependencies, filtersDrawerOpened, isDesktop, facetsRequestOptions) => {
  const [facetsData, setFacetsData] = useState(null);

  const validateResponse = (response) => {
    if (response.ok) return response;
  };

  useEffect(() => {
    (async () => {
      const shouldSendFacetsRequest = isDesktop || (!isDesktop && filtersDrawerOpened);
      const requestFiltersOnDemand = () =>
        shouldSendFacetsRequest ? searchApi.getFacets(facetsRequestOptions) : Promise.resolve(null);

      try {
        const facetsDataResponse = await requestFiltersOnDemand();
        const responseValidator = shouldSendFacetsRequest ? validateResponse : (r) => r;
        const facetsData = pipeSingleValue(facetsDataResponse)(responseValidator, getValidResponseBody);
        setFacetsData(facetsData);
      } catch (e) {
        console.error(e.message);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facetsDependencies, filtersDrawerOpened, isDesktop]);

  return facetsData;
};

export default useFacetsFetcher;
