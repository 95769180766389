import React from 'react';
import styles from './SearchRedirectMessage.module.scss';
import classNames from 'classnames';
import { FormattedHTMLMessage } from 'react-intl';
import { getPagePath } from '../../../utils/appRoutes';
import { ARD_SEARCH } from '../../../utils/appRoutes.definitions';

const SearchRedirectMessage = ({ className, from, to }) => {
  return (
    <div className={classNames(styles.root, className)}>
      <FormattedHTMLMessage
        id="search.searchRedirectMessage"
        values={{
          query: from,
          suggestion: to,
          suggestionHref: `${getPagePath(ARD_SEARCH)}?q=${encodeURIComponent(to)}`,
        }}
        defaultMessage="Sorry, we can not find anything for <b>'{query}'</b>. Showing results for <b>'<a>{suggestion}</a>'</b> instead."
      />
    </div>
  );
};

export default SearchRedirectMessage;
