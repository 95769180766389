import React from 'react';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl';

const HeadingH1 = ({ classList, term, page = 1, category, complete }) => {
  const intl = useIntl();
  if (complete) {
    return (
      <h1 className={classList} id="readyForCrawler">
        {term}
      </h1>
    );
  }
  if (term === 'korting') {
    return (
      <h1 className={classList} id="readyForCrawler">
        {page > 1 && <FormattedHTMLMessage defaultMessage="Page {page } - " id="search.pagePrefix" values={{ page }} />}
        <FormattedMessage defaultMessage="Discounts" id="search.discounts" />
      </h1>
    );
  }

  const pagePrefix = intl.formatMessage(
    {
      id: 'search.headings.pagePrefix',
      defaultMessage: 'Page {page} - ',
    },
    {
      page: page,
    }
  );
  const allProducts = intl.formatMessage({
    id: 'common.allProducts',
    defaultMessage: 'All products',
  });
  const allCategories = intl.formatMessage({
    id: 'common.allCategories',
    defaultMessage: 'all categories',
  });

  const titleTerm = term ? `'${term}'` : allProducts;
  const titleCategory = category || allCategories;
  const titlePage = page === 1 ? '' : pagePrefix;

  return (
    <h1 className={classList} id="readyForCrawler">
      <FormattedHTMLMessage
        defaultMessage="{page}<b>'{term}'</b> in {category}"
        id="search.headings.title"
        values={{ page: titlePage, term: titleTerm, category: titleCategory }}
      />
    </h1>
  );
};

export default HeadingH1;
