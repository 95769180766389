import React, { useState, useRef, useContext } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { ImageCropSearchContext } from './ImageCropSearchContext';

const ImageCropSearch = ({ url, className }) => {
  const [crop, setCrop] = useState();
  const { setImageCropData } = useContext(ImageCropSearchContext);
  const imgRef = useRef();
  const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
    return centerCrop(
      makeAspectCrop(
        {
          unit: '%',
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  };

  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const aspect = width / height;
    setCrop(centerAspectCrop(width, height, aspect));
  };

  const onComplete = (crop) => {
    const img = imgRef?.current;
    const width = img.width;
    const height = img.height;
    const cropBy = {
      width,
      height,
      top_left: [crop.x, crop.y],
      bottom_right: [crop.x + crop.width, crop.y + crop.height],
    };
    setImageCropData(cropBy);
  };

  return (
    <ReactCrop crop={crop} onChange={(c) => setCrop(c)} onComplete={onComplete}>
      <img ref={imgRef} src={url} className={className} onLoad={onImageLoad} />
    </ReactCrop>
  );
};

export default ImageCropSearch;
