/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import classnames from 'classnames';
import { FormattedMessage, FormattedHTMLMessage, useIntl } from 'react-intl';
import React, { useEffect, useState, useContext, useCallback } from 'react';
import { Link, useLocation, useNavigate, useLoaderData } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
//todo head: search term from router, locale, config, staticApi.getCategoriesData
import { DRAWER_DIRECTION_LTR } from '../Drawer/Drawer.definitions';
import Button, { PRIMARY } from '../Button/Button';

import { getValidResponseBody, searchApi, imageApi } from '../../api';

import useMediaQuery from '../../utils/useMediaQuery';

import { ReactComponent as ChevronDownIcon } from '../../images/icons/chevron_down.svg';
import { ReactComponent as ChevronUpIcon } from '../../images/icons/chevron_up.svg';
import { ReactComponent as FiltersIcon } from '../../images/icons/filters.svg';
import { ReactComponent as FilterIcon } from '../../images/icons/filter.svg';

import styles from './SearchResultsPage.module.scss';

import {
  SORTING_OPTIONS_NEW,
  SORTING_OPTIONS_PRICE_ASC,
  SORTING_OPTIONS_PRICE_DESC,
  SORTING_OPTIONS_RELEVANCE,
} from '../../utils/getSearchPayload';
import HeadingH1 from './HeadingH1';
import useFacetsFetcher from './useFacetsFetcher';
import { clipTextEllipsis } from '../../utils/textEllipsis';
import { SSRContext } from '../../utils/SSRContext';

import { VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES } from './SRP.definitions';
import loadVisibleComponent from '../../utils/loadVisibleComponent';
import SearchBreadcrumb from '../SearchBreadcrumb/SearchBreadcrumb';
import SearchRedirectMessage from './SearchRedirectMessage';
import { setSearchProducts } from '../../state/productSearch/productSearch';
import CategoryContentPageLink from '../CategoryContentPageLink';
import { SRPWrapper } from '../SRPWrapper/SRPWrapper';
import ImageSideBar from './ImageSideBar';
import { constructSRPCanonical, createPaginatedCanonicalUrl } from './constructSRPCanonical';
import { ImageCropSearchContext } from '../ImageCropSearch/ImageCropSearchContext';
import dataLayerPush from '../../utils/dataLayerPush';
import TopRelevantCategories from '../TopRelevantCategories';
import { SwitchableFeature } from '../../features/SwitchableFeature/SwitchableFeature';
import { TOP_RELEVANT_CATEGORIES } from '../../features/SwitchableFeature/SwitchableFeature.definitions';
import useNotifications from '../NotificationPopup/useNotifications';
import { useCookies } from 'react-cookie';
import { useConstructURL } from '../../utils/useConstructURL';

const ProductsList = loadable(() => import('Components/ProductsList/ProductsList'));
const StickyHeader = loadable(() => import('../StickyHeader/StickyHeader'));
const ViewOptions = loadable(() => import('./ViewOptions'));

const FiltersHomeDrawer = loadVisibleComponent(() => import('../FiltersHomeDrawer/FiltersHomeDrawer'));
const NoResultsPage = loadVisibleComponent(() => import('../NoResultsPage/NoResultsPage'));
const FacetsSidebar = loadVisibleComponent(() => import('../FacetsSidebar/FacetsSidebar'));
const RecentlyViewedProducts = loadVisibleComponent(() => import('../RecentlyViewedProducts/RecentlyViewedProducts'));
const Pagination = loadVisibleComponent(() => import('../Pagination/Pagination'));
const Footer = loadVisibleComponent(() => import('../Footer/Footer'));
const NewsletterSection = loadVisibleComponent(() => import('../NewsletterSection/NewsletterSection'));
const PageSizeDropdown = loadVisibleComponent(() => import('../PageSizeDropdown/PageSizeDropdown'));

const filterTruthyProperties = (obj) => {
  return Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(obj).filter(([_, value]) => {
      if (Array.isArray(value)) {
        // Check if the array has any non-nullish value
        return value.some((item) => item != null);
      }
      // Keep properties with truthy values
      return Boolean(value);
    })
  );
};

const SearchResultsPage = () => {
  const { sendNotification } = useNotifications();
  const dispatch = useDispatch();
  const config = useSelector(({ config }) => config);
  const intl = useSelector(({ intl }) => intl);
  const intl2 = useIntl();
  const {
    categoryData,
    searchData,
    searchApiOptions,
    authHeader,
    featuresFilter,
    filters,
    categoriesParamArray,
    maxProductsPerPage,
    priceFrom,
    priceTo,
    page,
    searchTerm,
    urlSortingOption,
    searchPayload,
  } = useLoaderData();
  const imageSearch = useSelector((state) => state.productSearch.imageUrl);
  const { searchProducts } = useSelector(({ productSearch }) => productSearch);
  const location = useLocation();
  const constructURL = useConstructURL();
  const searchParams = new URLSearchParams(location.search);
  const urlViewOption = searchParams.get('view');
  const selectedProductParam = searchParams.get('sel') || '';
  const [, setCookie] = useCookies(['search_filters_applied', 'search_query']);

  const sendSearchRequestEvent = useCallback(
    (searchTerm, isDesktopSSR, searchData, filtersApplied) => {
      const query = searchTerm;
      const expDate = new Date();
      expDate.setMonth(expDate.getMonth() + 1);

      setCookie('search_filters_applied', filtersApplied, {
        expires: expDate,
        path: '/',
      });

      setCookie('search_query', query, {
        expires: expDate,
        path: '/',
      });

      setCookie('search_id', searchData?.search_id, {
        expires: expDate,
        path: '/',
      });

      dataLayerPush({
        event: 'search_request',
        query,
        result_count: searchData?.hits?.hits?.length,
        result_total: searchData?.hits?.total?.value,
        filters_applied: JSON.stringify(filtersApplied || {}),
        search_id: searchData?.search_id,
      });
    },
    [setCookie]
  );

  const { search, hash } = location;

  const categoriesParam = categoriesParamArray.join('/'); //returns string in any case
  //backwards compatible value required after category pages routes update
  //to replace it with categoriesParam, you need to check if there's no code which depends on undefined value of categoryPath
  const categoryPath = categoriesParam || undefined;

  const rootCategory = categoriesParamArray[0];

  const navigate = useNavigate();
  const { isDesktopSSR } = useContext(SSRContext);
  const { imageCropData } = useContext(ImageCropSearchContext);
  const { isGreaterOrEqualTo, BREAKPOINTS } = useMediaQuery(isDesktopSSR);
  //todo: this one is not used anywhere. One assumtion: was used to force component update
  const [loadFeatures, setLoadFeatures] = useState(0);

  const isFashionView = rootCategory === 'kleding' || rootCategory === 'clothing';
  const isDesktop = isGreaterOrEqualTo(BREAKPOINTS.EXTRA_LARGE);
  const isTablet = isGreaterOrEqualTo(BREAKPOINTS.MEDIUM);
  const pageSize = parseInt(maxProductsPerPage, 10);
  const [imageProducts, setImageProducts] = useState([]);
  const [isLoadImageProduct, setIsLoadImageProduct] = useState(false);
  const [showMobileCategoryLink, setShowMobileCategoryLink] = useState(false);

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', left: 0, top: 0 });
    //todo: basically, update everytime search param changes
  }, [search]);

  //todo: extract filters handling
  const queryStringParams = new URLSearchParams(search.replace(/\|/g, '%7C'));
  let activeFilters = [
    //...categoriesParamArray.map((item, idx) => [`${'sub'.repeat(idx)}category`, item]),
    ...Array.from(queryStringParams.entries()).filter(
      ([key]) => !['q', 'page', 'pageSize', 'sel', 'sortBy', 'view', 'clk'].includes(key)
    ),
  ];

  //Get SEO data for categories from path
  //Use react router data loaders instead
  // const { data: categoryPageData, setData: setCategoryPageData, frontloadMeta: categoryPageReqMeta } = useFrontload(
  //   'categories-seo-data',
  //   async ({ staticApi }) => ({
  //   })
  // );
  //const { data: searchItems, setData: setSearchData } = useFrontload('searchdata', async ({ searchApi }) => ({
  // const { data: SRPData, setData: setSRPData, frontloadMeta: SRPReqMeta } = useFrontload(
  //   'searchdata',
  //   async ({ searchApi, staticApi }) => {
  //     const [searchData, categoriesSEOData] = await Promise.all([
  //       searchApi.getSearchData(options),
  //       !!categoriesJSONPath ? staticApi.getCategories2Data(categoriesJSONPath) : null,
  //     ]);

  //     return { searchData, categoriesSEOData };
  //   }
  // );

  //Get requested categories names
  const categoryFilter = categoryData?.map(({ name }) => name) || [];

  const facetsDependencies = Object.keys(searchParams).reduce((fd, key) => {
    if (!['size', 'currentPage', 'sort'].includes(key)) return { ...fd, [key]: searchParams[key] };
    else return fd;
  }, {});
  const facetsDependenciesString = JSON.stringify(facetsDependencies);
  const filtersDrawerOpened = hash.includes('filters');
  const featuresData = useFacetsFetcher(facetsDependenciesString, filtersDrawerOpened, isDesktop, searchApiOptions);
  const productVideosData = searchData?.videos;
  useEffect(() => {
    setImageProducts([]);
    if (imageSearch && imageSearch.id && imageCropData) {
      (async () => {
        try {
          setIsLoadImageProduct(true);
          const imageCropOptions = imageCropData ? imageCropData : {};
          const options = {
            headers: { ...authHeader, Accepts: '*/*', 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify({ id: imageSearch.id, ...imageCropOptions }),
          };
          const imageResults = await imageApi.getImageSearchResults(options);
          if (imageResults?.status !== 200) {
            sendNotification(intl2.formatMessage({ id: 'common.error.api' }));
            setImageProducts([]);
            return;
          }
          const searchDataIds = getValidResponseBody(imageResults)?.results?.map((item) => item[0]);
          if (searchDataIds.length) {
            const productItem = {
              method: 'post',
              headers: { ...authHeader },
              body: JSON.stringify({ ids: searchDataIds }),
            };

            const productsResponse = await searchApi.getWishlistProfile(productItem);
            const productsResponseBody = getValidResponseBody(productsResponse);
            if (productsResponseBody.length) {
              setImageProducts(productsResponseBody);
            }
          }
        } finally {
          setIsLoadImageProduct(false);
        }
      })();
    }
  }, [imageSearch, imageCropData]);

  useEffect(() => {
    if (!searchData?.hits?.hits?.length) return;
    const filtersApplied = filterTruthyProperties(searchPayload?.payload?.filters);

    sendSearchRequestEvent(searchTerm, isDesktopSSR, searchData, filtersApplied);
  }, [searchData, sendSearchRequestEvent]);

  useEffect(() => {
    if (searchData?.hits && JSON.stringify(searchData.hits) !== JSON.stringify(searchProducts)) {
      dispatch(setSearchProducts(searchData.hits));
    }
  }, [searchData?.hits]);

  const sortingOption = [
    SORTING_OPTIONS_RELEVANCE,
    SORTING_OPTIONS_PRICE_ASC,
    SORTING_OPTIONS_PRICE_DESC,
    SORTING_OPTIONS_NEW,
  ].includes(urlSortingOption)
    ? urlSortingOption
    : undefined;

  const defaultView = isDesktop ? VIEW_OPTIONS_TILES : VIEW_OPTIONS_COLUMNS;
  const viewOption = [VIEW_OPTIONS_COLUMNS, VIEW_OPTIONS_TILES].includes(urlViewOption) ? urlViewOption : defaultView;

  const numberTotalProducts = searchData && searchData?.hits?.total && searchData?.hits?.total?.value;

  const products =
    searchData &&
    searchData?.hits?.hits.length > 0 &&
    searchData?.hits?.hits.map((hit) => ({ ...hit._source, id: hit._id }));

  const otherFeatures = !featuresData
    ? []
    : Object.keys(featuresData)
        .filter(
          (prop) =>
            prop.startsWith('feature::') && featuresData[prop].nested_features.filter_feature.feature.buckets.length > 0
        )
        .sort((a, b) => (featuresData[a].wpIndex < featuresData[b].wpIndex ? -1 : 1))
        .map((prop) => featuresData[prop]);

  //searchData?.canonical param is present in search api response only when parameters are presented as id's
  //at this point this is the case only with perma search links like /n/some-text/435435+664456
  const canonicalUrl = constructSRPCanonical(searchData?.canonical, categoryPath, page, filters?.brands);
  const lastSubcategory = categoryFilter?.slice(-1)[0];
  //todo: return back if needed
  // const lastSubCategoryData = categoryData && categoryData[categoryData.length - 1];
  const totalPages = Math.ceil((numberTotalProducts > 10000 ? 10000 : numberTotalProducts) / maxProductsPerPage);
  //todo: return back if needed
  // const metaTitle = lastSubCategoryData?.meta?.yoast_wpseo_title?.replace(
  //   '%%numberProductsCategory%%',
  //   numberTotalProducts
  // );
  // const metaDescription = lastSubCategoryData?.meta?.yoast_wpseo_metadesc.replace(
  //   '%%numberProductsCategory%%',
  //   numberTotalProducts
  // );

  const categoryPageTitleNum = (!!categoriesParam && page > 1 && `P.${page} `) || '';
  //todo: avoid using intl.messages
  const categoryPageTitle =
    intl?.messages &&
    !!categoriesParam &&
    categoryPageTitleNum +
      intl.messages['search.title']
        .replace(/\{category\}/g, lastSubcategory)
        .replace(/\{website\}/g, config.websiteName);

  if (searchData && !searchData?.hits?.hits?.length && page > 1 && search.split('&').length === 1) {
    navigate(constructURL(location, { ignoreExistingQueryString: true }), { replace: true });
  }

  const searchRedirect = searchData?.warnings?.find(({ type }) => type === 'searchRedirect');
  //check if errors should be considered as no results too
  const noResults = searchData?.hits?.hits && searchData.hits.hits.length === 0;
  const searchTermOverride = searchData?.page_title?.trim();
  const capitalise = (text) => text[0].toUpperCase() + text.slice(1);
  const searchTermTitle = (searchTermOverride && capitalise(searchTermOverride)) || searchTerm;

  const searchResultsFullWidthLayout = classnames(styles.results, 'col-12-xs');
  const searchResultsRegularLayout = classnames(styles.results, 'col-12-xs col-9-xl', { ['col-10-xxl']: !imageSearch });
  const searchResultsClassName = searchRedirect ? searchResultsFullWidthLayout : searchResultsRegularLayout;
  return (
    <React.Fragment>
      {!categoriesParam && (
        <Helmet>
          <title>{clipTextEllipsis(searchTermTitle, 60)}</title>
        </Helmet>
      )}
      <Helmet>
        <meta property="og:url" content={canonicalUrl} />
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      {!!categoryPageTitle && (
        <Helmet>
          <title>{clipTextEllipsis(categoryPageTitle, 60)}</title>
        </Helmet>
      )}
      {/* todo: return back if needed */}
      {/* {!!categoriesParam && lastSubCategoryData?.meta?.yoast_wpseo_title && (
        <Helmet>
          <meta name="twitter:title" content={clipTextEllipsis(metaTitle, 70)} />
          <meta property="og:title" content={clipTextEllipsis(metaTitle, 50)} />
          <meta name="description" content={metaDescription} />
          <meta name="twitter:description" content={clipTextEllipsis(metaDescription, 200)} />
          <meta property="og:description" content={clipTextEllipsis(metaDescription, 160)} />
        </Helmet>
      )} */}
      <StickyHeader
        activeFilters={activeFilters}
        filters={searchPayload?.payload?.filters}
        searchTerm={searchTerm}
        selectedCategoriesData={categoryData}
        isSearchPage={true}
        categoriesInUrl={categoriesParamArray}
      />
      <div
        className={classnames(styles.root, 'container', {
          [styles.hasFilters]: !!activeFilters?.length,
        })}
      >
        <div className={classnames(styles.mainRow, 'row')}>
          {!!noResults && (
            <NoResultsPage
              spellingCorrections={searchData?.spellingCorrections}
              categoryData={categoryData}
              categoryFilter={categoryFilter}
              categoriesParamArray={categoriesParamArray}
              searchTerm={searchTerm}
            />
          )}
          {!noResults && (!searchData || !!searchData?.hits?.hits?.length) && (
            <>
              <aside
                className={classnames(styles.filters, 'col-12-xs col-3-xl ', {
                  ['col-2-xxl']: !imageSearch,
                  [styles.customFilters]: imageSearch,
                  [styles.hidden]: searchRedirect,
                })}
                data-testid="SRPfilters"
              >
                {!imageSearch && featuresData && !!searchData && (
                  <FacetsSidebar
                    featuresData={featuresData}
                    categoriesParamArray={categoriesParamArray}
                    config={config}
                    intl={intl}
                    searchPayload={searchPayload}
                    priceFrom={priceFrom}
                    priceTo={priceTo}
                    brandsFilter={filters?.brands}
                    shopsFilter={filters?.shops}
                    colorsFilter={filters?.colors}
                    sizesFilter={filters?.sizes}
                    modelsFilter={filters?.models}
                    featuresFilter={featuresFilter}
                    gendersFilter={filters?.genders}
                    otherFeatures={otherFeatures}
                    deliveryFilter={filters?.delivery}
                  />
                )}
                {imageSearch && <ImageSideBar />}
              </aside>
              <main className={searchResultsClassName} id="SearchResultsPage" data-testid="SearchResultsPage">
                <div className={classnames('row', styles.filtersViewOptionsWrapper)}>
                  {!!searchTermOverride && (
                    <HeadingH1
                      classList={classnames(styles.searchTermHeading, 'col-12-xs')}
                      page={page}
                      term={searchTermTitle}
                    />
                  )}
                  {!searchTermOverride && (
                    <HeadingH1
                      classList={classnames(styles.searchTermHeading, 'col-12-xs')}
                      page={page}
                      term={searchTerm}
                      category={categoryFilter?.[categoryFilter.length - 1]}
                    />
                  )}
                  <SwitchableFeature feature={TOP_RELEVANT_CATEGORIES}>
                    <TopRelevantCategories topCategories={searchData?.top_categories} />
                  </SwitchableFeature>
                  {!imageSearch && (
                    <>
                      <div className={classnames(styles.searchNav, 'col-12-xs')}>
                        <SearchBreadcrumb
                          categories={categoryData}
                          categoriesParamArray={categoriesParamArray}
                          classList={{ root: styles.searchBreadcrumb }}
                        />
                        {!!lastSubcategory && !!categoryPath && (
                          <CategoryContentPageLink category={lastSubcategory} categoryPath={categoryPath} isHidden />
                        )}
                      </div>
                    </>
                  )}
                  {imageSearch && (
                    <div className="col-12-xs">
                      <h2 className={styles.imageSearchTitle}>
                        {' '}
                        <FormattedMessage defaultMessage="Similar product" id="search.imageResult" />
                      </h2>
                    </div>
                  )}
                  <div className={classnames(styles.divider, 'col-12-xs')} />
                  {!imageSearch && (
                    <>
                      <Link
                        className={classnames(styles.filtersBtnContainer, 'col-12-xs col-5-xl')}
                        to={constructURL('#filters')}
                      >
                        <Button
                          classList={{ root: styles.filtersBtn }}
                          type={PRIMARY}
                          onClick={() => setLoadFeatures(loadFeatures + 1)}
                        >
                          {activeFilters.length === 0 && (
                            <>
                              <FiltersIcon className={styles.filtersIcon} />
                              <p>
                                <FormattedMessage defaultMessage="Filter" id="filters.single" />
                              </p>
                              <b>{numberTotalProducts}</b>
                              <FormattedMessage defaultMessage="Results" id="search.results" />
                            </>
                          )}
                          {activeFilters.length > 0 && (
                            <>
                              <FormattedHTMLMessage
                                defaultMessage="{activeFilters, plural, one {<b>#</b> filter} other {<b>#</b> filters}}"
                                id="filter.pluralFilters"
                                values={{ activeFilters: activeFilters.length }}
                              />
                              <FiltersIcon className={styles.filtersIcon} />
                              <b>{numberTotalProducts}</b>
                              <FormattedMessage defaultMessage="Results" id="search.results" />
                            </>
                          )}
                        </Button>
                      </Link>
                      <ul className={classnames(styles.sortingOptions, 'col-12-xs col-6-m')}>
                        <li>
                          <Link
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]: sortingOption === SORTING_OPTIONS_RELEVANCE,
                            })}
                            to={constructURL(`?sortBy=${SORTING_OPTIONS_RELEVANCE}`)}
                          >
                            <FormattedMessage defaultMessage="Relevance" id="sortOrders.relevance" />
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]: [
                                SORTING_OPTIONS_PRICE_ASC,
                                SORTING_OPTIONS_PRICE_DESC,
                              ].includes(sortingOption),
                            })}
                            data-testid="sortingOptionPrice"
                            to={constructURL(
                              `?sortBy=${
                                sortingOption !== SORTING_OPTIONS_PRICE_ASC
                                  ? SORTING_OPTIONS_PRICE_ASC
                                  : SORTING_OPTIONS_PRICE_DESC
                              }`
                            )}
                          >
                            <FormattedMessage defaultMessage="Price" id="sortOrders.price" />
                            {[SORTING_OPTIONS_PRICE_ASC, SORTING_OPTIONS_PRICE_DESC].includes(sortingOption) ? (
                              sortingOption === SORTING_OPTIONS_PRICE_ASC ? (
                                <ChevronDownIcon className={styles.sortingOptionDirectionIcon} />
                              ) : (
                                <ChevronUpIcon className={styles.sortingOptionDirectionIcon} />
                              )
                            ) : null}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className={classnames(styles.sortingOption, {
                              [styles.sortingOptionSelected]: sortingOption === SORTING_OPTIONS_NEW,
                            })}
                            to={constructURL(`?sortBy=${SORTING_OPTIONS_NEW}`)}
                          >
                            <FormattedMessage defaultMessage="New" id="sortOrders.new" />
                          </Link>
                        </li>
                        {!!lastSubcategory && !!categoryPath && (
                          <li
                            className={styles.showCategoryLink}
                            onClick={() => setShowMobileCategoryLink(!showMobileCategoryLink)}
                          >
                            <FilterIcon />
                            {showMobileCategoryLink ? (
                              <FormattedMessage defaultMessage="Show less" id="common.showLess" />
                            ) : (
                              <FormattedMessage id="common.showMore" defaultMessage="show More" />
                            )}
                          </li>
                        )}
                      </ul>
                      <div className={classnames('col-12-xs', styles.mobileCategoryLink)}>
                        {!!lastSubcategory && !!categoryPath && (
                          <CategoryContentPageLink
                            category={lastSubcategory}
                            categoryPath={categoryPath}
                            isHidden={!showMobileCategoryLink}
                          />
                        )}
                      </div>
                      <div className={classnames(styles.resultsCounter, 'col-12-xs col-5-xl')}>
                        <FormattedHTMLMessage
                          defaultMessage="{numberResults, plural, one {<b>#</b> result} other {<b>#</b> results}}"
                          id="search.resultsCounter"
                          values={{ numberResults: numberTotalProducts }}
                        />
                      </div>
                      <ViewOptions isTablet={isTablet} viewOption={viewOption} />
                    </>
                  )}
                </div>
                {!!searchRedirect && <SearchRedirectMessage from={searchRedirect?.from} to={searchRedirect?.to} />}
                {/* {(isServer || !searchData) && <ProductPlaceholder view={viewOption} />} */}
                {/* {!searchData && <ProductPlaceholder view={viewOption} />} */}
                {imageSearch && isLoadImageProduct && <div className={styles.spinner}></div>}
                {((searchData?.hits?.hits?.length > 0 && !imageSearch) ||
                  (imageSearch && imageProducts.length > 0 && !isLoadImageProduct)) && (
                  <ProductsList
                    classList={{
                      root: classnames({ [styles.viewOptionsColumns]: viewOption === VIEW_OPTIONS_COLUMNS }),
                    }}
                    isFashionView={isFashionView}
                    view={imageSearch ? VIEW_OPTIONS_TILES : viewOption}
                    page={page}
                    products={imageSearch ? imageProducts : products}
                    isDesktop={isDesktop}
                    selectedProductHash={selectedProductParam}
                    searchData={searchData}
                    videos={productVideosData}
                  />
                )}
                {!imageSearch && (
                  <div className={classnames(styles.paginationAndSummaryContainer)}>
                    <div className={classnames(styles.summary)}>
                      <FormattedHTMLMessage
                        defaultMessage="<b>{startingProductNumber}</b> - {endingProductNumber} from {totalProductNumber}"
                        id="pagination.listSummary"
                        values={{
                          startingProductNumber: (page - 1) * maxProductsPerPage + 1,
                          endingProductNumber:
                            maxProductsPerPage < numberTotalProducts ? page * maxProductsPerPage : numberTotalProducts,
                          totalProductNumber: numberTotalProducts,
                        }}
                      />
                    </div>
                    <div className={classnames(styles.summary)}>
                      <PageSizeDropdown selected={pageSize} />
                    </div>
                    {searchData?.hits?.total?.value > 0 && (
                      <Pagination
                        classList={{
                          root: classnames(styles.pagination),
                        }}
                        currentPage={parseInt(page, 10)}
                        totalPages={totalPages}
                        createPaginatedCanonicalUrl={createPaginatedCanonicalUrl(filters?.brands, categoryPath)}
                      />
                    )}
                  </div>
                )}
              </main>
            </>
          )}
        </div>
      </div>
      {!imageSearch && (
        <SearchBreadcrumb categories={categoryData} categoriesParamArray={categoriesParamArray} isMobile />
      )}
      <RecentlyViewedProducts />
      <NewsletterSection />
      <Footer />
      {featuresData && (
        <FiltersHomeDrawer
          brands={featuresData.brand}
          categories={featuresData?.category?.buckets}
          colors={featuresData?.color?.buckets?.filter((item) => item.key)}
          direction={DRAWER_DIRECTION_LTR}
          filterValues={{
            brandsFilter: filters?.brands,
            categoryFilter,
            colorsFilter: filters?.colors,
            featuresFilter,
            gendersFilter: filters?.genders,
            modelsFilter: filters?.models,
            deliveryFilter: filters?.delivery,
            sizesFilter: filters?.sizes,
            priceFrom,
            priceTo,
            shopsFilter: filters?.shops,
            sortBy: queryStringParams.get('sortBy'),
          }}
          searchPayload={searchPayload}
          genders={featuresData?.gender?.buckets}
          models={featuresData.model}
          priceRanges={featuresData?.price_ranges?.ranges?.buckets}
          prices={featuresData?.price?.buckets}
          otherFeatures={otherFeatures}
          shops={featuresData.campaign}
          sizes={featuresData?.size?.buckets}
          trigger="filters"
          activeFilters={activeFilters}
        />
      )}
    </React.Fragment>
  );
};

const SearchResultsPageWrapped = () => {
  return (
    <SRPWrapper>
      <SearchResultsPage />
    </SRPWrapper>
  );
};

export default SearchResultsPageWrapped;
