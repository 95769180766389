import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useImageSearch } from '../../../utils/useImageSearch';
import Button from '../../Button/Button';
import styles from './ImageSideBar.module.scss';

import { useSwitchableFeatures } from '../../../features/SwitchableFeature/SwitchableFeature';
import { IMAGE_SEARCH_CROP } from '../../../features/SwitchableFeature/SwitchableFeature.definitions';
import ImageCrop from '../../ImageCropSearch';

const ImageSideBar = () => {
  const [imageCropEnabled] = useSwitchableFeatures([IMAGE_SEARCH_CROP]);
  const { imageSearch, handleTriggerUploadImage } = useImageSearch();

  return (
    <div className={styles.root}>
      <img src={imageSearch.url} className={styles.imageBackground} />
      <p className={styles.title}>
        <FormattedMessage id="search.image.look" defaultMessage="Looks like it is:" />
      </p>
      {imageCropEnabled && <ImageCrop className={styles.imageSearch} url={imageSearch.url} />}
      {!imageCropEnabled && <img src={imageSearch.url} className={styles.imageSearch} />}
      <Button classList={{ root: styles.action }} onClick={() => handleTriggerUploadImage(true)}>
        <FormattedMessage id="search.image.uploadOtherImage" defaultMessage="Upload anther image" />
      </Button>
    </div>
  );
};

export default ImageSideBar;
