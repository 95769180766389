import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setImageSearch, setTriggerUploadImage } from '../state/productSearch/productSearch';
// import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import { useNavigate } from 'react-router-dom';
import { getPagePath } from './appRoutes';
import { ARD_SEARCH } from './appRoutes.definitions';
import { useAuth0Wrapper } from './auth0';
import imageApi from '../api/imageApi';
import { getValidResponseBody } from '../api';
import { useIntl } from 'react-intl';

// const region = 'eu-west-1';

export const useImageSearch = () => {
  const { tokenClaims } = useAuth0Wrapper();
  const authToken = tokenClaims?.__raw;
  const navigate = useNavigate();
  const imageSearch = useSelector((state) => state.productSearch.imageUrl);
  const openUploadImage = useSelector((state) => state.productSearch.openUploadImage);
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const intl = useIntl();
  const srpPath = getPagePath(ARD_SEARCH);
  const headers = {};
  const [historyImageSearch, setHistoryImageSearch] = useState([]);
  if (authToken) {
    headers.Authorization = `Bearer ${authToken}`;
  }

  useEffect(() => {
    const localImageSearch = JSON.parse(localStorage.getItem('imageSearch') || '[]');
    if (Array.isArray(localImageSearch) && localImageSearch.length) {
      const validImages = localImageSearch.filter((img) => img.id && img.url);
      setHistoryImageSearch(validImages);
    }
  }, []);

  const handleDeleteHistoryImage = (id) => {
    const newHistoryImageSearch = historyImageSearch.filter((img) => img.id !== id);
    setHistoryImageSearch(newHistoryImageSearch);
    localStorage.setItem('imageSearch', JSON.stringify(newHistoryImageSearch));
  };

  const handleSearchOldImage = (image) => {
    handleSetImageUploadUrl(image);
    navigate(srpPath);
  };

  const handleTriggerUploadImage = (value) => {
    dispatch(setTriggerUploadImage(value));
  };
  const handleSetImageUploadUrl = (value) => dispatch(setImageSearch(value));

  // const uploadFileToS3 = async (file) => {
  //   if (!file) {
  //     return;
  //   }
  //   // Create an S3 client
  //   const s3Client = new S3Client({
  //     region,
  //     credentials: {
  //       accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,
  //       secretAccessKey: process.env.REACT_APP_S3_SECRET_ACCESS_KEY,
  //     },
  //   });
  //   try {
  //     const encodedFileName = encodeURIComponent(file.name);
  //     const bucket = process.env.REACT_APP_S3_BUCKET;
  //     // Prepare the parameters for the PutObjectCommand
  //     const params = {
  //       Bucket: bucket,
  //       Key: encodedFileName,
  //       Body: file,
  //       ContentType: file.type,
  //     };
  //     // Execute the PutObjectCommand
  //     const command = new PutObjectCommand(params);
  //     await s3Client.send(command);
  //     const imageUrl = `${process.env.REACT_APP_S3_CDN}/${encodedFileName}`;
  //     handleSearchImage({ url: imageUrl, id: '' });
  //   } catch (error) {
  //     console.error('Error uploading image:', error);
  //   }
  // };

  const handleSearchImage = (image) => {
    handleSetImageUploadUrl(image);
    navigate(srpPath);
  };

  const uploadImageFile = async (file) => {
    if (!file) {
      return;
    }
    if (errorMessage) {
      setErrorMessage('');
    }
    const maxSize = 10 * 1024 * 1024;
    if (file.size >= maxSize) {
      setErrorMessage(intl.formatMessage({ id: 'search.image.error' }));
      throw errorMessage;
    }
    try {
      const formData = new FormData();
      formData.append('file', file);
      const options = {
        headers: { ...headers, Accepts: '*/*' },
        method: 'POST',
        body: formData,
      };
      const res = await imageApi.uploadImageFile(options);
      const response = await res.json();
      if (response.id) {
        handleSearchImage({
          id: response.id,
          url: `data:${response.mime};base64,${response.data}`,
        });
      }
    } catch (error) {
      console.error(error);
      setErrorMessage(intl.formatMessage({ id: 'search.image.error' }));
      throw error;
    }
  };

  const uploadImageLink = async (link) => {
    if (!link) {
      return;
    }
    try {
      const options = {
        headers: { ...headers, Accepts: '*/*' },
        method: 'POST',
        body: JSON.stringify({ url: link }),
      };
      const res = await imageApi.uploadImageLink(options);
      const response = getValidResponseBody(res);

      if (response?.id) {
        handleSearchImage({
          id: response.id,
          url: `data:${response.mime};base64,${response.data}`,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadSearchImage = async (file, onFinish) => {
    try {
      await uploadImageFile(file);
      onFinish();
    } catch (error) {
      console.error(error);
    }
  };

  const onImageSearchFileChange = (onFinish) => (e) => {
    if (e.target.files !== null && e.target.files[0]) {
      handleUploadSearchImage(e.target.files[0], onFinish);
    }
  };

  return {
    imageSearch,
    openUploadImage,
    handleSetImageUploadUrl,
    handleTriggerUploadImage,
    uploadImageFile,
    uploadImageLink,
    handleSearchImage,
    errorMessage,
    setErrorMessage,
    historyImageSearch,
    handleDeleteHistoryImage,
    handleSearchOldImage,
    onImageSearchFileChange,
  };
};
